import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  colors, Theme, Row, Col, Loading,
  H2, Text, Badge,
  CollapsiblePanels
} from '@commonsku/styles';
import withReducers from '../../store/withReducers';
import mpSlice, { selectors, fetchMpCampaigns, fetchMPContactActivity } from '../marketing-platforms/mpSlice';
import {
  formatDateTimeStr,
  getContactMP,
  transformHubspotContactActivity,
  transformMailchimpContactActivity
} from '../marketing-platforms';

const borderStyle = `1px solid ${colors.primary0}`;

function MarketingPlatformContact(props) {
  const dispatch = useDispatch();
  const loading = useSelector(selectors.loading);
  const valid_mp_contact = props.isMpContact;
  const platform = useSelector(selectors.platform);
  const contact = useSelector(({entities}) => {
    return entities.company_contacts[Object.keys(entities.company_contacts)[0]];
  });
  const contact_activity = useSelector(selectors.mp_contact_activity);
  const contact_mp = React.useMemo(() => getContactMP(contact ? contact.mp_contact : {}), [contact]);

  useEffect(() => {
    dispatch(fetchMpCampaigns({ check_mp_contact: 0, contact_id: contact.contact_id, email: contact.contact_email }));
  }, []);

  useEffect(() => {
    if (valid_mp_contact) {
      const params = {check_mp_contact: 0};
      dispatch(fetchMPContactActivity(contact.contact_email, contact.contact_id, params));
    }
  }, [valid_mp_contact, contact]);

  if (!valid_mp_contact) {
    return (<>
      <div className="main-content">
        <Row style={{paddingLeft: 10, paddingRight: 10}}>
          <Col xs style={{textAlign: 'center'}}>
          {loading ? <Loading /> : (<>
            <Text style={{color: colors.cta, fontSize: '1.5em'}}>This contact does not exist in the marketing platform</Text>
          </>)}
          </Col>
        </Row>
      </div>
    </>);
  }

  const commons_props = {
    platform,
    activity: contact_activity,
  };

  return (<Theme>
    <div className="main-content">
      <Row style={{paddingLeft: 10, paddingRight: 10}}>
        <Col xs>
          {contact_mp.sync_status ? <H2>
            {contact_mp.mp} <Text style={{fontSize: 12, fontStyle: 'italic'}}>
              <Badge style={{
                backgroundColor: ['FAILED', 'FAILED_EMAIL', 'FAILED-EMAIL'].includes(contact_mp.sync_status) ? colors.cta :
                  (contact_mp.sync_status == 'PENDING' ? colors.primary100 : colors.primary),
                verticalAlign: 'super',
              }}>{contact_mp.sync_status}</Badge>
              <Text>
                <br/>Last Synced: <Text style={{fontWeight: 50}}>{formatDateTimeStr(contact_mp.last_synced)}</Text>
              </Text>
            </Text>
          </H2>: null}
        </Col>
        <Col xs md={8} mdOffset={2}>
          {loading ? <Loading /> : (<ContactActivity {...commons_props} />)}
        </Col>
      </Row>
    </div>
  </Theme>);
}

function ContactActivityPanelTitle({ campaign, contactCampaignStats }) {
  if(!campaign || !contactCampaignStats) {
    return null;
  }
  return (
    <>
      <Row>
        <Col xs={6}>{_.get(campaign, 'campaignName', '')}</Col>
        <Col xs={3} style={{color: '#2c3e50'}}>
          <Text><strong>Opens:</strong> {contactCampaignStats.Opens}, <strong>Clicks:</strong> {contactCampaignStats.Clicks}</Text>
        </Col>
        <Col xs={3} style={{color: '#2c3e50'}}>
          <Text>{formatDateTimeStr(_.get(campaign, 'send_time', null))}</Text>
        </Col>
      </Row>
    </>
  );
}

function ContactActivity({platform, activity, campaignId}) {
  const campaigns = useSelector(selectors.mp_campaigns);
  const activity_data = React.useMemo(() => (platform === 'mailchimp'
    ? transformMailchimpContactActivity({
      campaigns, activity, campaignId
    }) : platform === 'hubspot'
    ? transformHubspotContactActivity({
      campaigns, activity, campaignId
    }) : {}), [platform, campaigns, activity, campaignId]);

  return (<>
    <Row>
      <Col xs padded style={{marginBottom: 10, borderBottom: borderStyle}}>
        <Row>
          <Col xs={6} padded style={{fontWeight: 'bold'}}>Campaign</Col>
          <Col xs={6} padded style={{fontWeight: 'bold'}}>Action</Col>
        </Row>
      </Col>

      <Col xs padded>
        <CollapsiblePanels spaceBetween panels={Object.keys(activity_data).map((k, i) => {
          const data = activity_data[k];
          return {
            // eslint-disable-next-line react/display-name
            title: <ContactActivityPanelTitle
              key={`ContactActivityPanelTitle_${i}`}
              platform={platform}
              campaign={_.get(_.first(data), 'campaign', null)}
              contactCampaignStats={_.get(_.first(data), 'contactCampaignStats', null)}
            />,
            children: data.map((v, j) => {
              const style = j < data.length-1 ? {borderBottom: borderStyle} : {};
              return (<>
                <Row key={'collapse_campaign_'+i+'_'+j} style={style}>
                  <Col xs={6} padded></Col>
                  <Col xs={3} padded>
                    <Text>{v.action}</Text>
                  </Col>
                  <Col xs={3} padded>
                    <Text>{v.actionDateStr}</Text>
                  </Col>
                  {/* {v.campaign && v.campaign.campaignStats && j == data.length-1 ? <>
                    <Col padded xs style={{marginTop: 30, borderTop: `2px solid ${colors.primary0}`}}>
                      <Box padded controls={<H2 style={{marginBottom: 0, textAlign: 'left'}}>Campaign Details</H2>} borderless>
                        <Row padded>
                          {Object.keys(v.campaign.campaignStats).map((sKey, sIdx) => {
                            const val = v.campaign.campaignStats[sKey];
                            return (
                              <Col key={'campaign_stat_'+sIdx} xs={val.colSize || 3} style={{border: borderStyle}}>
                                <Text as="p" style={{textAlign: 'center'}}>
                                  <strong>{sKey}:</strong> <br/>{val.value}{val.isPercentage ? '%' : null}
                                </Text>
                              </Col>
                            );
                          })}
                        </Row>
                      </Box>
                    </Col>
                  </> : null} */}
                </Row>
              </>);
            })
          };
        })} />
      </Col>
    </Row>
  </>);
}

export default withReducers(connect(null, null)(MarketingPlatformContact), {
  [mpSlice.name]: mpSlice.reducer,
});
