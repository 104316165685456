import _ from 'lodash';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Text } from '@commonsku/styles';
import { useHasCapabilities } from '../hooks';
import { getIdentityUtils } from '../utils';

const activeClassName = ({ isActive }) => isActive ? "active" : "";

class ContactMenu extends Component {
  render() {
    const { contact, identity, isMpContact } = this.props;
    const { hasCapabilities } = getIdentityUtils(identity);
    const v2 = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');

    return (
      <>
        {v2 ? <div className="sub-right-menu-top">
          <Text as="p" className="sub-right-menu-top-title">Contact</Text>
        </div> : null}
        <ul className="vertical menu">
          <li className="show-for-small-only"><a href={identity.user_flags.includes('DASHBOARD-HOME') ? '/dashboard.php' : '/home.php'}>Home</a></li>
          <li><NavLink end to={`/contact/${contact.company_contact_id}`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Overview</NavLink></li>
          <li><NavLink end to={`/contact/${contact.company_contact_id}/projects`} className={activeClassName}>Projects</NavLink></li>
          {isMpContact ? <li><NavLink end to={`/contact/${contact.company_contact_id}/marketing-platform`} className={activeClassName}>Marketing</NavLink></li> : null}
        </ul>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactMenu);
