import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation, Routes, Route, BrowserRouter } from "react-router-dom";
import ContactApp from '../containers/ContactApp';
import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';

const AppRoute = React.memo(({ panel }) => {
  const params = useParams();
  const location = useLocation();

  if (panel) {
    return <ContactApp panel={panel} params={params} location={location} />;
  }

  return <NoMatch location={location} />;
});

const ContactRouter = ({ contact_first_name, contact_last_name, location }) => {
  useEffect(() => {
    document.title = `${contact_first_name} ${contact_last_name}`;
  }, [contact_first_name, contact_last_name]);

  return (
    <BrowserRouter basename={location}>
      <Routes>
        <Route
          path="/contact/:company_contact_id"
          element={<AppRoute panel="notes" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/contact/:company_contact_id/projects"
          element={<AppRoute panel="projects" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/contact/:company_contact_id/marketing-platform"
          element={<AppRoute panel="marketing-platform" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path={"*"}
          element={<AppRoute noMatch />}
          ErrorBoundary={ErrorBoundary}
        />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    contact_first_name: state.entities.company_contacts[Object.keys(state.entities.company_contacts)[0]].contact_first_name,
    contact_last_name: state.entities.company_contacts[Object.keys(state.entities.company_contacts)[0]].contact_last_name,
  };
};

export default connect(mapStateToProps)(ContactRouter);
