import { filter } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LabelledInput from './LabelledInput';
import DropdownMenu, { MenuTrigger } from './DropdownMenu';

import { createUpdateContact } from '../actions/contact';
import { createAddTemp, createDeleteTemp } from '../actions/temp';
import NewProjectPopup from './project/NewProjectPopup';
import { withRouter } from './helpers';

const EditContactBtn = ({ showEditContactBtn, displayNone, company_contact_id, onCreateAddTemp }) => {
    const navigate = useNavigate();
    const handleEditFromLink = () => {
        onCreateAddTemp();
        window.scrollTo(0, 0);
        navigate(`/contact/${company_contact_id}`);
    };
    return (
        <a className="hollow button save-contact-button"
            onClick={handleEditFromLink}
            style={!showEditContactBtn ? displayNone : null}
        >Edit Contact</a>
    );
};

class ContactTitle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editContact: props.editContact,
            editName: props.editContact ? true : false,
            editClient: props.editContact ? true : false,
            editSocial: props.editContact ? true : false,
            contact_first_name: props.contact.contact_first_name,
            contact_last_name: props.contact.contact_last_name,
            contact_position: props.contact.contact_position,
            company_name: props.contact.company_name,
            contact_facebook: props.contact.contact_facebook,
            contact_twitter: props.contact.contact_twitter,
            contact_linkedin: props.contact.contact_linkedin,
            showNewProjectPopup: false,
        };

        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleFinishUpdateContactTitle = this.handleFinishUpdateContactTitle.bind(this);
        this.renderActionMenu = this.renderActionMenu.bind(this);
        this.handleClickNewProject = this.handleClickNewProject.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            contact_first_name: nextProps.contact.contact_first_name,
            contact_last_name: nextProps.contact.contact_last_name,
            contact_position: nextProps.contact.contact_position,
            company_name: nextProps.contact.company_name,
            contact_twitter: nextProps.contact.contact_twitter,
            contact_facebook: nextProps.contact.contact_facebook,
            contact_linkedin: nextProps.contact.contact_linkedin,
            editContact: nextProps.editContact ? true : false,
            editName: nextProps.editContact ? true : false,
            editClient: nextProps.editContact ? true : false,
            editSocial: nextProps.editContact ? true : false,
        });
    }

    handleChangeValue(e, field) {
        this.setState({ [field]: e});
    }

    handleFinishUpdateContactTitle() {
        this.props.onSaveContact();
        this.setState({ editName: false, editClient: false, editSocial: false, editContact: false });
    }

    handleClickNewProject() {
        this.setState({ showNewProjectPopup: true });
    }

    renderActionMenu() {
      const actionsOptions = filter([
          { value: <div>New Project</div>,
            show: true, hideOnClick: true, onClick: this.handleClickNewProject
          },
          { value: <a rel="opener" href={`/send_email.php?parent_type=CLIENT&parent_id=${this.props.contact.company_id}&company_type=CLIENT&contact_id=${this.props.contact.contact_id}`}
                      target="_blank">Send Email</a>,
            show: true, hideOnClick: true
          },
      ], {show: true});

      return (
        <DropdownMenu className="actions-dropdown action-menu"
          options={actionsOptions} align="right">
          <MenuTrigger>
            <a className="button">Actions</a>
          </MenuTrigger>
        </DropdownMenu>
      );
    }

    render() {
        const { contact, showEditContactBtn, onCreateUpdateContact, onCreateAddTemp } = this.props;
        const contact_name = `${this.state.contact_first_name} ${this.state.contact_last_name}`;
        const contact_position = this.state.contact_position ? `${this.state.contact_position} at ` : null;
        const account_url = `/client.php?id=${contact.company_id}`;
        const marginTop50 = {
            marginTop: '50px'
        };
        const displayNone = {
            display: 'none'
        };
        const colorGray = {
            color: 'gray'
        };

        return (
          <div>
            <div className="large-8 columns" style={this.state.editName && this.state.editClient && this.state.editSocial ? marginTop50 : null}>
                {!this.state.editName && !this.state.editSocial ?
                    <div className="row no-maxWidth">
                        <div className="social-media-links" style={{display: 'inline-block'}}>
                            <h2 style={{float: 'left'}}>{contact_name}</h2>
			    {0 == contact.active && <span style={{ marginLeft: '1rem' }}>(Inactive)</span>}
                            <a href={this.state.contact_twitter != '' ? this.state.contact_twitter : 'javascript:void(0);'} target="_blank" className="social-media-link"><i className="fi-social-twitter" style={this.state.contact_twitter == '' ? colorGray : null}></i></a>
                            <a href={this.state.contact_facebook != '' ? this.state.contact_facebook : 'javascript:void(0);'} target="_blank" className="social-media-link"><i className="fi-social-facebook" style={this.state.contact_facebook == '' ? colorGray : null}></i></a>
                            <a href={this.state.contact_linkedin != '' ? this.state.contact_linkedin : 'javascript:void(0);'} target="_blank" className="social-media-link"><i className="fi-social-linkedin" style={this.state.contact_linkedin == '' ? colorGray : null}></i></a>
                        </div>
                    </div>
                :
                    <div className="small-12">
                        <div className="medium-6 columns" style={{paddingLeft: 0}}>
                            <LabelledInput className="small-12" label="First Name" type="text" placeholder="first name" value={this.state.contact_first_name} onChange={e => this.handleChangeValue(e, 'contact_first_name')} onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_first_name', contact.contact_contact_first_name)}/>
                        </div>
                        <div className="medium-6 columns">
                            <LabelledInput className="small-12" label="Last Name" type="text" placeholder="last name" value={this.state.contact_last_name} onChange={e => this.handleChangeValue(e, 'contact_last_name')} onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_last_name', contact.contact_last_name)}/>
                        </div>
                    </div>
                }
                {!this.state.editClient ?
                    <h4>{contact_position}<a href={account_url} target="_blank">{this.state.company_name}&nbsp;&nbsp;</a></h4>
                :
                    <div className="small-12">
                        <div className="medium-6 columns" style={{paddingLeft: 0}}>
                            <LabelledInput className="small-12" label="Contact Position" type="text" placeholder="contact position" value={this.state.contact_position} onChange={e => this.handleChangeValue(e, 'contact_position')} onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_position', contact.contact_position)}/>
                        </div>
                    </div>
                }
            </div>
            <div className="large-4 columns social-media-links">
                {!this.state.editContact ? (
                    1 == contact.active ? (
                        <div>
                            <EditContactBtn
                                company_contact_id={contact.company_contact_id}
                                displayNone={displayNone}
                                onCreateAddTemp={onCreateAddTemp}
                                showEditContactBtn={showEditContactBtn}
                            />
                            &nbsp;
                            {this.renderActionMenu()}
                        </div>) : (<div>
                            <a className="button save-contact-button" onClick={() => onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'active', 0)(1)}>Reactivate</a>
                        </div>)
                )
                :
                    <div>
                        <a className="hollow button save-contact-button" onClick={this.handleFinishUpdateContactTitle} style={!showEditContactBtn ? displayNone : null}>Save Contact</a>
                        &nbsp;
                        {this.renderActionMenu()}
                        <div className="small-12 columns">
                            <LabelledInput className="small-12" label="Twitter" type="text" placeholder="twitter handle" value={this.state.contact_twitter} onChange={e => this.handleChangeValue(e, 'contact_twitter')} onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_twitter', contact.contact_twitter)} />
                            <LabelledInput className="small-12" label="Facebook" type="text" placeholder="facebook handle" value={this.state.contact_facebook} onChange={e => this.handleChangeValue(e, 'contact_facebook')} onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_facebook', contact.contact_facebook)} />
                            <LabelledInput className="small-12" label="Linkedin" type="text" placeholder="linkedin public profile url" value={this.state.contact_linkedin} onChange={e => this.handleChangeValue(e, 'contact_linkedin')} onBlur={onCreateUpdateContact(contact.company_contact_id, contact.company_id, 'contact_linkedin', contact.contact_linkedin)} />
                        </div>
                    </div>
                }
            </div>
              {this.state.showNewProjectPopup
                && <NewProjectPopup
                  client_id={contact.company_id}
                  billing_contact_id={contact.contact_id}
                  onClose={() => this.setState({ showNewProjectPopup: false })}
                />}
          </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    "/projects/";
    const showEditContactBtn = !(
        ownProps.router.location.pathname.match(/projects/) ||
        ownProps.router.location.pathname.match(/marketing-platform/)
    );
    const editContact = state.temp['edit-contact'] && showEditContactBtn ? true : false;

    return {
        editContact,
        showEditContactBtn
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateUpdateContact: (contact_id, client_id, field, previous_value) => value => {
      dispatch(createUpdateContact(contact_id, client_id, field, previous_value, value));
    },
    onSaveContact: () => {
        dispatch(createDeleteTemp('edit-contact'));
    },
    onCreateAddTemp: () => {
        dispatch(createAddTemp('edit-contact', true));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactTitle));
