import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loadContactProjects } from '../actions/contact';
import { createDeleteTemp } from '../actions/temp';

import { formatDate, formatMoney, makeCancelable, oauth, getUserImageSrc } from '../utils';

const ORDER_TYPE_SORT = {
  "OPPORTUNITY": 0,
  "PRESENTATION": 1,
  "ESTIMATE": 2,
  "SALES ORDER": 3,
  "INVOICE": 4
};

class ContactProjects extends Component{
  constructor(props) {
    super(props);

    this.state = {
      filterValue: "",
      filterType: ""
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeFilterType = this.handleChangeFilterType.bind(this);
  }

  componentDidMount() {
    const { onCreateDeleteTemp, onLoadContactProjects, company_contact_id, projects } = this.props;

    onCreateDeleteTemp();

    if (Object.values(projects).length === 0) {
      const data = {
        id: company_contact_id,
        type: "renderContactProjects"
      };
      oauth("GET", "contact/renderContactProjects", data).then(
        ({ json }) => onLoadContactProjects(json.projects)
      );
    }
  }

  handleChangeInput(e) {
    this.setState({ filterValue: e.target.value });
  }

  handleChangeFilterType(e) {
    this.setState({ filterType: e.target.value });
  }

  render() {
    const { filterValue, filterType } = this.state;
    const searchFilter = filterValue.toLowerCase();

    const projects = Object.values(this.props.projects).filter(
      p => {
        if (filterType && !p.order_types.includes(filterType)) {
          return false;
        }
        if (!searchFilter) {
          return true;
        }
        if (`${p.job_number}`.indexOf(searchFilter) > -1) {
           return true;
        }
        if (p.job_name.toLowerCase().indexOf(searchFilter) > -1) {
          return true;
        }
        if (`${p.sales_rep.user_first_name} ${p.sales_rep.user_last_name}`.toLowerCase().indexOf(searchFilter) > -1) {
          return true;
        }
        if (`${p.client_rep.user_first_name} ${p.client_rep.user_last_name}`.toLowerCase().indexOf(searchFilter) > -1) {
          return true;
        }
        return false;
      }
    ).sort(
      (a, b) => b.job_number - a.job_number
    );

    let icon_index = 0;
    return(
      <div className="main-content">
        <div className="small-12 columns project-row row no-maxWidth">
          <div className="small-3 columns">
            <input
              value={filterValue}
              placeholder="Filter by number, name, or rep…"
              style={{height: '2.4375rem', width: '100%'}}
              onChange={this.handleChangeInput}
            />
          </div>
          <div className="small-3 columns">
            <select value={filterType} onChange={this.handleChangeFilterType}>
              <option value="">Order types</option>
              <option value="PRESENTATION">Presentation</option>
              <option value="ESTIMATE">Estimate</option>
              <option value="SALES ORDER">Sales Order</option>
              <option value="INVOICE">Invoice</option>
            </select>
          </div>
          <div className="small-3 columns">
            &nbsp;
          </div>
        </div>
        {projects.map(p =>
          <div className="small-12 columns project-row row no-maxWidth" key={p.job_id}>
            <div className="small-2 columns large-number">
              <a href={`/project/${p.job_number}`} target="_blank">#{p.job_number}</a>
            </div>
            <div className="small-3 columns">
              <a href={`/project/${p.job_number}`} target="_blank" style={{wordBreak: 'break-word'}}>{p.job_name} (${formatMoney(p.latest_total_amount)})</a><br />
              <small>Created on {formatDate(p.date_created)}</small>
            </div>
            <div className="small-4 columns">
              {!_.isEmpty(p.client_rep) ?
                <div className="rep-block">
                  <img src={getUserImageSrc(p.client_rep)} className="avatar" />
                  <small>Client Rep</small><br />
                  <a href={`/user.php?id=${p.client_rep.user_id}`} target="_blank">{`${p.client_rep.user_first_name} ${p.client_rep.user_last_name}`}</a>
                </div>
              : null}
              {!_.isEmpty(p.sales_rep) ?
                <div className="rep-block">
                  <img src={getUserImageSrc(p.sales_rep)} className="avatar" />
                  <small>Order Rep</small><br />
                  <a href={`/user.php?id=${p.sales_rep.user_id}`} target="_blank">{`${p.sales_rep.user_first_name} ${p.sales_rep.user_last_name}`}</a>
                </div>
              : null}
            </div>
            <div className="small-3 columns projects-icons">
              {p.order_types.filter(
                ot => ot !== "OPPORTUNITY"
              ).sort(
                (a, b) => (ORDER_TYPE_SORT[a] ?? 0) - (ORDER_TYPE_SORT[b] ?? 0)
              ).map(
                (ot, index) => (
                  <div key={`${ot}-${index}`} className="order-icon">{ot.charAt(0)}</div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const projects = state.entities.projects;
  const company_contact_id = Object.values(state.entities.company_contacts ?? {})?.[0]?.company_contact_id;
  return {
    projects,
    company_contact_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoadContactProjects: (projects) => {
      dispatch(loadContactProjects(projects));
    },
    onCreateDeleteTemp: () => {
        dispatch(createDeleteTemp('edit-contact'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactProjects);
