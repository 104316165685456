import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { colors } from '@commonsku/styles';

import Header from '../containers/Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import ContactTitle from '../components/ContactTitle';
import ContactMenu from '../components/ContactMenu';
import ContactNotes from '../components/ContactNotes';
import ContactProjects from '../components/ContactProjects';
import MarketingPlatformContact from '../components/contact/MarketingPlatformContact';

import { createNewFeaturePopup } from '../actions/popup';

import { createAddTemp } from '../actions/temp';
import { oauth } from '../utils';
import createPopup from '../popup-factory';

class ContactApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editContact: props.editContact,
      showMarketingLink: false,
    };

    this.handleEditContactView = this.handleEditContactView.bind(this);
    this.setShowMarketingLink = this.setShowMarketingLink.bind(this);
  }

  componentDidMount() {
    const { user_id, onCreateNewFeaturePopup } = this.props;

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ editContact : nextProps.editContact });
  }

  handleEditContactView() {
    this.setState({ editContact: true });
    this.props.onEditContact();
  }

  setShowMarketingLink() {
    this.setState({ showMarketingLink: true });
  }

  renderPanel(panel) {
    const { project, order, showMarketingLink } = this.state;
    const isMpContact = showMarketingLink || this.props.contact.mp_contact != null;

    switch (panel) {
      case 'notes':
        return <ContactNotes setShowMarketingLink={this.setShowMarketingLink} />;
      case 'projects':
        return <ContactProjects />;
      case 'marketing-platform':
        return isMpContact ? <MarketingPlatformContact isMpContact={isMpContact} /> : (<div className="main-content">
          <p style={{color: colors.cta, textAlign: 'center', fontSize: '1.5em'}}>This contact does not exist in the marketing platform</p>
        </div>);
      default:
        return null;
    }
  }

  render() {
    const { panel, contact, location, editContact, popups } = this.props;
    const isMpContact = this.state.showMarketingLink || this.props.contact.mp_contact != null;
    const menu = <ContactMenu contact={contact} isMpContact={isMpContact} />;
    return (
      <ErrorBoundary>
        <Header menu={menu}>
          <ContactTitle contact={contact} location={location} handleEditContactView={this.handleEditContactView} />
        </Header>
        <MainSection menu={menu}>
          {this.renderPanel(panel)}
        </MainSection>
        {popups.map((p, idx) => createPopup(p, idx))}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const editContact = state.temp['edit-contact'] ? true : false;
  return {
    contact: state.entities.company_contacts[Object.keys(state.entities.company_contacts)[0]],
    editContact,
    popups: _.get(state, 'display.popups', []),
    user_id: state.identity.user_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onEditContact: () => {
      dispatch(createAddTemp('edit-contact', true));
    },
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactApp);
