import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import Select from './Select';
import MentionTextarea from './MentionTextarea';
import { getUserOptions } from '../selectors/dropdowns';
import { createAddContactMessage } from '../actions/message';
import { createDeleteTemp, createAddTemp } from '../actions/temp';

import DateInput from './DateInput';

class PublisherContact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      showReminder : 0,
      reminder_date: '',
      reminder_user_id: props.default_reminder_user_id,
      publisher_text_value: this.props.message ? this.props.message : '',
      note_type: '',
      date_complete: ''
    };

    this.handleAddMessage = this.handleAddMessage.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.handleToggleReminder = this.handleToggleReminder.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeReminderUser = this.handleChangeReminderUser.bind(this);
    this.handlePublisherText = this.handlePublisherText.bind(this);
    this.handleChangeNoteType = this.handleChangeNoteType.bind(this);
    this.closePublisher = this.closePublisher.bind(this);
    this.handleChangeDateCompleted = this.handleChangeDateCompleted.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.loading === false) {
      this.setState({
        files: [],
        showReminder: 0,
        reminder_user_id: this.props.default_reminder_user_id,
        publisher_text_value: '',
      });
    }
  }

  componentWillUnmount(){
    this.props.onCreateAddTemp('message', this.state.publisher_text_value);
  }

  renderPreview(files) {
    this.setState({
      files: files
    });
  }

  handleAddMessage(contact_id, publisher_text, publisher_reminder_user_id, publisher_reminder_date, files, default_reminder_user_id, client_id, note_type, date_complete) {
    let patt = /(@[A-z0-9]+)/g;
    let user_ids_arr = [];
    let m;
    do {
      m = patt.exec(publisher_text);
      if(m && this.props.mention_users.filter(u => u.mask == m[0].slice(1)).length != 0) {
        user_ids_arr.push(this.props.mention_users.filter(u => u.mask == m[0].slice(1))[0]['user_id']);
      }
    } while(m);
    let tagged_users = user_ids_arr.toString();
    this.props.onAddContactMessage(contact_id, publisher_text, publisher_reminder_user_id, publisher_reminder_date, files, tagged_users, client_id, null, null, note_type, date_complete);
    this.setState(Object.assign({}, this.state, { files: [] }));
    this.setState({
      showReminder: 0,
      reminder_user_id: this.props.default_reminder_user_id,
      publisher_text_value: '',
      note_type: ''
    });
  }

  handleToggleReminder(showReminder) {
    if(showReminder == 1) {
      this.setState({ showReminder: 0 });
    }else{
      this.setState({ showReminder: 1});
    }
  }

  handleChangeDate(value) {
    this.setState({ reminder_date: value });
  }

  handleChangeDateCompleted(value) {
    this.setState({ date_complete: value });
  }

  handleChangeReminderUser(e) {
    this.setState({ reminder_user_id: e});
  }

  handleChangeNoteType(type){
    this.setState({ note_type: type});
  }

  closePublisher() {
    this.setState({
      files: [],
      showReminder : 0,
      reminder_date: '',
      reminder_user_id: this.props.default_reminder_user_id,
      publisher_text_value: '',
      note_type: '',
      date_complete: ''
    });
  }

  handlePublisherText(value) {
    this.setState({ publisher_text_value: value });
  }

  getCurrentTime() {
    return Date.now();
  }

  render() {
    const { contact_id, client_id, users, mention_users, default_reminder_user_id, loading } = this.props;
    const {
      files, reminder_date, reminder_user_id, note_type, date_complete
    } = this.state;
    const text_value = this.state.publisher_text_value;
    const hideReminder = {
      'display' : 'none'
    };
    return (
      <div>
        {this.state.note_type == '' ?
          <div className="publisher pre-publisher row no-maxWidth">
            <div className="large-4 columns">
              <a className="button small" onClick={e => {e.preventDefault(); this.handleChangeNoteType('NOTE');}}><i className="fi-clipboard-notes"></i> Note</a>
            </div>
            <div className="large-4 columns">
              <a className="button small" onClick={e => {e.preventDefault(); this.handleChangeNoteType('CALL');}}><i className="fi-telephone"></i> Call</a>
            </div>
            <div className="large-4 columns">
              <a className="button small" onClick={e => {e.preventDefault(); this.handleChangeNoteType('MEETING');}}><i className="fi-torsos-all"></i> Meeting</a>
            </div>
          </div>
        :
          <div className="publisher">
            <div className="row no-maxWidth">
              <div className="medium-12 columns">
                <MentionTextarea placeholder="Add a note, call or a meeting..."
                  value={text_value} mention_users={mention_users}
                  onChange={this.handlePublisherText}/>
                <div className="medium-8 columns">
                  {files[0] && files[0].name.match(/\.(jpg|jpeg|png|gif)$/) ?
                    <img className="attachment-preview" src={files[0].preview} />
                  : null}
                  {files[0] && !files[0].name.match(/\.(jpg|jpeg|png|gif)$/) ?
                    `${files[0].name} ready to be uploaded.`
                  : null}
                </div>
                <div className="medium-4 columns">
                  {loading ?
                    <div className="text-center"><br /><img src="/images/gears.gif" /><br /></div>
                  : null}
                </div>
              </div>
              <div className="large-3 columns" style={{ paddingRight: 0 }}>
                {note_type === 'CALL' || note_type === 'MEETING' ?
                  <div>
                    <DateInput
                      value={date_complete}
                      onChange={this.handleChangeDateCompleted}
                      onBlur={this.handleChangeDateCompleted}
                      dateFormat='yyyy-mm-dd HH:mm'
                      inputFormat='YYYY-MM-DD HH:mm'
                      outputFormat='YYYY-MM-DD HH:mm'
                      placeholder='YYYY-MM-DD HH:mm'
                      showTimeInput={true}
                      updateOnDateClick={false}
                      collapseOnDateClick={false}
                      showOkButton={true}
                      showCancelButton={true}
                      showTodayButton={true}
                      defaultValue={this.getCurrentTime} />
                  </div>
                : null}
              </div>
              <div className={this.state.note_type == 'NOTE' && this.state.showReminder == 1 ? "large-4 columns" : "large-3 columns"}>
                <a className="button" onClick={e => {e.preventDefault(); this.handleToggleReminder(this.state.showReminder);}}><i className="fi-clock">&nbsp;&nbsp;</i> Reminder</a>
              </div>
              <div className={this.state.note_type == 'NOTE' && this.state.showReminder == 1 ? "large-4 columns" : "large-3 columns"}>
                <a className="button">
                  <Dropzone onDrop={this.renderPreview} style={{border: 'none'}} multiple={false}>
                    <><i className="fi-paperclip">&nbsp;&nbsp;</i> File</>
                  </Dropzone>
                </a>
              </div>
              <div className={this.state.note_type == 'NOTE' && this.state.showReminder == 1 ? "large-4 columns" : "large-3 columns"}>
                <a className="button" onClick={this.closePublisher}>Cancel</a>
              </div>
              <div className="large-6 columns" style={this.state.showReminder == 0 ? hideReminder : null }>
                <Select ref="publisher_reminder_user_id" value={reminder_user_id} options={users} change={this.handleChangeReminderUser} />
              </div>
              <div className="large-3 columns" style={this.state.showReminder == 0 ? hideReminder : null }>
                <DateInput value={this.state.reminder_date} onChange={this.handleChangeDate} showClock={false} />
              </div>
              <div className="large-3 columns">
                <a className="button alt" onClick={e => {
                  e.preventDefault();
                  this.handleAddMessage(
                    contact_id, text_value, reminder_user_id, reminder_date,
                    files, default_reminder_user_id, client_id, note_type, date_complete
                  );
                }} disabled={text_value != '' ? false : true} style={{float: 'right'}}>Post</a>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    default_reminder_user_id: state.identity.user_id,
    users: getUserOptions(state),
    mention_users: Object.values(state.entities.users).filter(u => u.mask != false),
    loading: state.display.loading.upload_file,
    message: state.temp.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddContactMessage: (contact_id, text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, details_type, note_type, date_complete) => {
      dispatch(createAddContactMessage(contact_id, text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, details_type, note_type, date_complete));
    },
    onCreateDeleteTemp: key => {
      dispatch(createDeleteTemp(key));
    },
    onCreateAddTemp: (key, value) => {
      dispatch(createAddTemp(key, value));
    }
  };
};

const ConnectedPublisherContact = connect(mapStateToProps, mapDispatchToProps)(PublisherContact);

export default ConnectedPublisherContact;
