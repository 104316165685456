import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PublisherContact from './PublisherContact';
import Feed from './Feed';
import ContactBriefing from './ContactBriefing';
import FeedFilter from './FeedFilter';

import { syncMessages } from '../actions/message';
import { loadContactProjects } from '../actions/contact';
import { createUpdateDisplayCommenting } from '../actions/display';
import { createAddTemp } from '../actions/temp';

import { oauth } from '../utils';
import { withRouter } from './helpers';

class ContactNotes extends Component{
  constructor(props) {
    super(props);

    this.state = {
      messages: props.messages
    };

    _.bindAll(this, ['loadMessagesFromServer', 'loadContactProjectsFromServer', 'startRefresh', 'stopRefresh']);
  }

  UNSAFE_componentWillMount() {
    if (this.props.editContact) {
      this.props.onEditContact();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      messages: Object.values(nextProps.messages).sort((a, b) => b.latest_update - a.latest_update),
    });

    if (nextProps.editContact) {
      this.props.onEditContact();
    }

    if (nextProps.commenting !== this.props.commenting) {
      if (nextProps.commenting) {
        this.stopRefresh();
      } else {
        this.startRefresh();
      }
    }
  }

  componentDidMount() {
    if (!this.props.commenting) {
      this.startRefresh();
    }
    this.loadContactProjectsFromServer();
  }

  componentWillUnmount() {
    this.stopRefresh();
    this.props.onStopCommenting();
  }

  startRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(this.loadMessagesFromServer, this.props.newsfeed_refresh_interval);
  }

  stopRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  loadMessagesFromServer() {
    const data = {
      company_contact_id: this.props.contact.company_contact_id
    };
    return oauth('GET', 'newsfeed/contact', data).then(
      ({ json }) => {
        this.props.onSyncMessages(json.contactFeed);
      }
    );
  }

  loadContactProjectsFromServer() {
    const data = {
      id: this.props.contact.company_contact_id,
      type: 'renderContactProjects'
    };
    return oauth('GET', 'contact/renderContactProjects', data).then(
      ({ json }) => {
        this.props.onloadContactProjects(json.projects);
      }
    );
  }

  render() {
    const { contact, messages, editContact, onSyncMessages, onloadContactProjects, setShowMarketingLink } = this.props;

    return(
      <div className="main-content project-notes">
        <div className="medium-12 large-8 columns">
          <PublisherContact contact_id={contact.contact_id} client_id={contact.company_id} />
          <FeedFilter posts={Object.values(this.state.messages)} filters={[
            FeedFilter.ContactFilter,
            FeedFilter.SearchFilter,
          ]}>
            <Feed comment_allowed={true} />
          </FeedFilter>
        </div>
        <div className="medium-12 large-4 columns">
          <div>
            <ContactBriefing contact={contact} setShowMarketingLink={setShowMarketingLink} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const editContact = ownProps.router.location.hash == '#edit-contact' ? true : false;

  return{
    contact: state.entities.company_contacts[Object.keys(state.entities.company_contacts)[0]],
    messages: state.entities.messages,
    editContact,
    commenting: state.display.commenting,
    newsfeed_refresh_interval: state.display.newsfeed_refresh_interval
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSyncMessages: (messages) => dispatch(syncMessages(messages)),
    onloadContactProjects: (projects) => dispatch(loadContactProjects(projects)),
    onStopCommenting: () => dispatch(createUpdateDisplayCommenting(false)),
    onEditContact: () => {
      dispatch(createAddTemp('edit-contact', true));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactNotes));
